/*
MAIN LAYOUT

Kleurenpallet
https://www.canva.com/colors/color-palettes/summer-splash/
*/

#root {
  max-width: 1500px;
  margin: 0 auto;
}

body {
  background-color: #05445e;
  color: #d4f1f4;
  font-family: Questrial, sans-serif;
  padding: 48px 64px 0px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.main {
  display: grid;
  grid-template-columns: 250px 1fr;
  gap: 48px;
}

/* ELEMENTS */

.comments {
  background-color: #189ab4;
  margin-bottom: 40px;
  padding: 16px 32px;
  border-radius: 16px;
  display: flex;
  align-items: center;
}

.commentsRecept {
  font-style: italic;
}

ul {
  list-style: none;
  padding-inline-start: 0px;
}

h1 {
  font-size: 42px;
  text-transform: uppercase;
  font-family: Righteous, sans-serif;
}

.logo {
  display: flex;
  align-items: center;
  gap: 16px;
}

.recept-formulier {
  background-color: #189ab4;
  margin-bottom: 10px;
  padding: 16px 32px;
  border-radius: 16px;
  display: flex;
  align-items: center;
}

.recept-formulier input,
.comments input,
.recept-formulier select {
  width: 220px;
  border: none;
  padding: 16px;
  border-radius: 100px;
  font-size: 18px;
  margin-right: 32px;
  font-family: inherit;
}

.comments input {
  width: 100%;
  margin-right: 32px;

}

.recept-formulier input:first-child {
  flex-grow: 1;
}

.recept {
  font-size: 20px;
  background-color: #189ab4;
  display: flex;
  align-items: center;
  border-radius: 16px;
  margin: 10px;
  padding: 10px;
  justify-content: space-between;
}

.source:link,
.source:visited {
  color: #a8a29e;
  text-decoration: none;
  margin-left: 12px;
  transition: 0.3s;
}

.source:hover,
.source:active {
  color: #3b82f6;
}

.categorie {
  margin-bottom: 16px;
}

.btn {
  border: none;
  font-family: Righteous, sans-serif;
  text-transform: uppercase;
  font-size: 20px;
  padding: 16px 0 13px;
  background-image: linear-gradient(135deg, #3b82f6, #16a34a, #a37616, #26e415);
  transition: 0.3s;
  color: inherit;
  border-radius: 100px;
  cursor: pointer;
}

.btn:hover {
  transform: scale(110%) rotate(-2deg);
}

.btn-catgorie {
  width: 100%;
  background: none;
}

.btn-alle-categorien {
  margin-bottom: 32px;
  width: 100%;
}

.btn-groot {
  font-size: 25px;
  padding: 10px 30px 10px 30px;
}

.tag {
  padding: 4px 12px;
  margin: 5px;
  border-radius: 100px;
  text-transform: uppercase;
  font-size: 16px;
}

.vote-buttons {
  margin-left: auto;
  flex-shrink: 0;

  display: flex;
  gap: 8px;
}

.vote-button button {
  border: none;
  background-color: #75e6da;
  font-size: 18px;
  padding: 10px 12px 14px 12px;
  border-radius: 100px;
  color: #05445e;
  font-family: inherit;
  font-weight: 600;
  cursor: pointer;
  transition: 0.3s;
  margin-left: 20px;
}

.vote-button-fav button {
  border: none;
  background-color: #05445e;
  font-size: 18px;
  padding: 10px 12px 14px 12px;
  border-radius: 100px;
  color: #05445e;
  font-family: inherit;
  font-weight: 600;
  cursor: pointer;
  transition: 0.3s;
  margin-left: 20px;
}

.vote-button:hover button {
  background-color: #05445e;
  color: #75e6da;
}

.vote-button-fav:hover button {
  background-color: #75e6da;
  color: #75e6da;
}

/* @media (max-width: 900px) {

} */
 
@media (max-width: 900px) {
  .main {
    grid-template-columns: 1fr;
    height: auto;
  }
  
  #root {
    padding: 0px;
  }
 
  body {
    padding: 48px 0px 0px;
  }
  
  .header {
    flex-direction: column;
    gap: 16px;
  }
 
  .recept-formulier {
    flex-direction: column;
    align-items: stretch;
    gap: 16px;
    padding: 16px px;
  }
 
  .recept {
    padding: 12px 16px;
    flex-direction: column    
  }



.recept-formulier input,
.recept-formulier select {
  width: auto;
  margin-right: 0px;
}

h1 {
  font-size: 32px;
  text-transform: uppercase;
  font-family: Righteous, sans-serif;
}

.logo {
  display: flex;
  align-items: center;
  gap: 16px;
}
}